<template>
  <div>
    <a
      href="javascript: void(0)"
      data-toggle="tooltip"
      data-placement="top"
      @click="fnShowModal()"
      ><i class="fas fa-upload text-info fs-800"></i
    ></a>
    <b-modal
      ref="policeCheckStatusModal"
      size="sm"
      hide-footer
      title="Police check status"
    >
      <div class="d-block">
        <form v-on:submit.prevent="updatePoliceCheckStatusModal">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="" for="type">Ref. no {{ userData.id }}</label>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label class="" for="type">Status</label>
                <vue-dropzone
                  ref="docUpload1"
                  id="docUpload1"
                  :useCustomSlot="true"
                  :options="dropzoneOptions"
                  v-on:vdropzone-sending="fnSendingDoc1"
                  @vdropzone-queue-complete="fnAfterSendingDoc1"
                  class="quoteDocUpload mb800"
                >
                  <div class="dropzone-custom-content text-center p300">
                    <div class="subtitle">
                      <i class="fas fa-cloud-upload-alt fs-1000 text-info"></i
                      ><span class="subtitle fs-900 pl-2"
                        >Upload document/s</span
                      >
                    </div>
                    <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                    <span v-if="loading">Uploading...</span>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import store from "../../store/modules/base.module";
import vueDropzone from "vue2-dropzone";
import * as authService from "../../services/auth.service";

export default {
  name: "ModalUploadPoliceCheckFromNSS",
  props: ["propsData"],
  components: {
    vueDropzone,
  },
  data() {
    return {
      errors: {},
      userData: {
        id: "",
        status: "",
      },
      loading: false,
    };
  },
  computed: {
    dropzoneOptions() {
      return {
        url: `${store.state.apiURL}/police-check/document-upload`,
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
        thumbnailWidth: 75,
        maxFilesize: 200,
        uploadMultiple: true,
        maxFiles: 10,
        paralleUploads: 10,
        previewsContainer: false,
        acceptedFiles: ".pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .png",
      };
    },
  },
  methods: {
    ...mapActions(["fetchPoliceCheckList"]),
    fnShowModal() {
      this.userData = this.propsData;
      this.showModal();
    },
    hideModal() {
      this.$refs.policeCheckStatusModal.hide();
    },
    showModal() {
      this.$refs.policeCheckStatusModal.show();
    },
    // Dropzone document upload
    fnSendingDoc1(file, xhr, formData) {
      this.loading = true;

      formData.append("pId", this.userData.id);
      formData.append("documentTypeId", 71);
    },
    // Dropzone document upload after
    fnAfterSendingDoc1(file) {
      this.fetchPoliceCheckList();
      this.loading = false;
      this.hideModal();
    },
  },
};
</script>
